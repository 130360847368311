var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState, } from 'react';
import { API_END_POINT } from '../api/api';
import { useEnquetePageviewHistoriesAdd } from '../api/use-enquete-pageview-histories-add';
import { MessageContext } from '../context/message-context';
import { replacePlaceholder } from '../utils/EnqueteHelper';
import { lineBreakToBr, lineBreakToParagraphAndHtml, } from '../utils/browserUtils';
import { TextFieldErrorMessage } from './FirstStep/TextFieldErrorMessage';
var OTHER_VALUE = '__OTHER__';
var SELECT_NOT_SELECTED_VALUE = '__NOT_SELECTED__';
var isEmptyValue = function (fieldValue) {
    if (fieldValue == undefined) {
        return true;
    }
    if (fieldValue.other) {
        return false;
    }
    return ((fieldValue.values || []).length == 0 &&
        (fieldValue.value || '').trim().length == 0);
};
var isErrorTextValidate = function (textValidationType, fieldValue) {
    if (textValidationType === 'number') {
        return (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== undefined && isNaN(Number(fieldValue.value));
    }
    else if (textValidationType === 'alphanumeric') {
        var alphanumericRegex = /^[A-Za-z0-9]*$/;
        return ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== undefined &&
            !alphanumericRegex.test(fieldValue.value));
    }
    else if (textValidationType === 'email') {
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== undefined &&
            !emailRegex.test(fieldValue.value || ''));
    }
    return false;
};
/** 入力検証 */
var validateFields = function (enqueteSetting, enqueteForm) {
    var errorFields = {
        name: false,
        nps: false,
        comment: false,
        fieldsIds: [],
    };
    // お名前
    if (enqueteSetting.visibleInputName && !enqueteForm.name) {
        errorFields.name = true;
    }
    // NPS(非表示の場合は必須チェックしない)
    if (!enqueteForm.nps && !enqueteSetting.npsHidden) {
        errorFields.nps = true;
    }
    // コメント （必須チェック）
    if (enqueteSetting.commentRequired !== false && !enqueteForm.comment) {
        errorFields.comment = true;
    }
    // コメント （最低文字数チェック）
    if (enqueteForm.nps &&
        3 <= enqueteForm.nps &&
        enqueteSetting.commentMinLength &&
        enqueteSetting.commentMinLength > enqueteForm.comment.length) {
        errorFields.comment = true;
    }
    // アンケート項目
    var errorUids = enqueteSetting.fields
        .filter(function (field) {
        var fieldValue = enqueteForm.fieldValues.find(function (fieldValue) { return fieldValue.fieldUid === field.uid; });
        return ((field.required !== false && isEmptyValue(fieldValue)) ||
            (field.type === 'text' &&
                field.textValidationType &&
                isErrorTextValidate(field.textValidationType, fieldValue)));
    })
        .map(function (field) { return field.uid; });
    errorFields.fieldsIds = errorUids;
    return errorFields;
};
var hasErrors = function (errorFields) {
    return (errorFields.name ||
        errorFields.nps ||
        errorFields.comment ||
        errorFields.fieldsIds.length > 0);
};
var scrollToElement = function (element) {
    var rect = element.getBoundingClientRect().top;
    var offset = window.pageYOffset;
    var target = rect + offset - 10;
    window.scrollTo({
        top: target,
        behavior: 'smooth',
    });
};
var scrollToNextQuestion = function (targetElement) {
    if (targetElement == null)
        return;
    var scrollAmount = window.pageYOffset;
    var elementPosition = targetElement.getBoundingClientRect().top;
    var elementHight = targetElement.clientHeight;
    var target = scrollAmount + elementPosition + elementHight;
    // 目標のスクロール位置までアニメーション
    window.scrollTo({
        top: target - 20,
        behavior: 'smooth',
    });
};
export default function FirstStep(props) {
    var enquete = props.enquete, enqueteSetting = props.enqueteSetting, enqueteForm = props.enqueteForm, setEnqueteForm = props.setEnqueteForm, isOpenedOnce = props.isOpenedOnce, setIsOpenedOnce = props.setIsOpenedOnce;
    var _a = useState(false), submitting = _a[0], setSubmitting = _a[1];
    var _b = useState(enqueteSetting.privacyPolicy ? false : true), isAgreePrivacyPolicy = _b[0], setIsAgreePrivacyPolicy = _b[1];
    var msg = useContext(MessageContext).msg;
    var _c = useState({
        name: false,
        nps: false,
        comment: false,
        fieldsIds: [],
    }), errorFields = _c[0], setErrorFields = _c[1];
    useEffect(function () {
        var errorElement = document.querySelector('.error-text');
        if (!errorElement) {
            return;
        }
        scrollToElement(errorElement);
    }, [errorFields]);
    useEffect(function () {
        setIsAgreePrivacyPolicy(enqueteSetting.privacyPolicy ? false : true);
    }, [enqueteSetting.privacyPolicy]);
    useEffect(function () {
        setIsOpenedOnce(true);
    }, []);
    useEnquetePageviewHistoriesAdd(enquete.uid, isOpenedOnce);
    var handleSubmit = function (event) {
        event.preventDefault();
        // プレビューの場合、次の画面へ遷移しない
        var searchParams = new URL(window.location.href).searchParams;
        var modeParam = searchParams.get('mode');
        if (modeParam === 'preview') {
            alert('プレビュー時はこの操作は実行できません。');
            return;
        }
        var newErrorFields = validateFields(enqueteSetting, enqueteForm);
        // エラーがある場合、処理を中断
        if (hasErrors(newErrorFields)) {
            setErrorFields(__assign({}, newErrorFields));
            return;
        }
        var responseValues = {
            name: enqueteForm.name,
            nps: enqueteForm.nps,
            comment: enqueteForm.comment,
            fieldValues: enqueteForm.fieldValues.map(function (fieldValue) {
                return {
                    fieldUid: fieldValue.fieldUid,
                    value: fieldValue.value,
                    values: fieldValue.values,
                    other: fieldValue.other,
                    otherValue: fieldValue.otherValue,
                };
            }),
        };
        var values = __assign(__assign({}, responseValues), { language: enqueteSetting.lang });
        var step = enqueteSetting.snsLink !== undefined &&
            enqueteSetting.completedActionType !== 'none'
            ? 2
            : 3;
        if (enqueteForm.debug) {
            window.scrollTo(0, 0);
            setEnqueteForm(__assign(__assign({}, enqueteForm), { step: step }));
            return;
        }
        setSubmitting(true);
        var inflowSourceParam = searchParams.get('_src');
        var url = new URL(API_END_POINT + "/public/enquetes/" + enquete.uid);
        if (inflowSourceParam) {
            url.searchParams.append('inflowSource', inflowSourceParam);
        }
        axios
            .post(url.toString(), values)
            .then(function (res) {
            window.scrollTo(0, 0);
            var responseId = res.data.responseId;
            var createdAt = res.data.createdAt;
            if (enqueteSetting.completedActionType === 'none') {
                window.location.hash = 'thanks';
                setEnqueteForm(__assign(__assign({}, enqueteForm), { step: step, hasGoogleAccount: false, responseId: responseId,
                    createdAt: createdAt }));
            }
            else {
                if (enqueteSetting.snsLink !== undefined) {
                    window.location.hash = 'step2';
                    setEnqueteForm(__assign(__assign({}, enqueteForm), { step: step, responseId: responseId,
                        createdAt: createdAt }));
                }
                else {
                    window.location.hash = 'gmb';
                    setEnqueteForm(__assign(__assign({}, enqueteForm), { step: step, responseId: responseId,
                        createdAt: createdAt }));
                }
            }
        })
            .finally(function () {
            setSubmitting(false);
        });
    };
    var checkFieldError = function (id) {
        var tf = false;
        errorFields.fieldsIds.forEach(function (errorFieldsId) {
            if (errorFieldsId === id) {
                tf = true;
            }
        });
        return tf;
    };
    var fieldSections = enqueteSetting.fields.map(function (field) {
        var npsSection = enqueteSetting.npsPositionAfterFieldUid ===
            field.uid && (React.createElement(NpsSection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, error: errorFields.nps }));
        return (React.createElement(React.Fragment, null,
            React.createElement(FieldSection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, field: field, error: checkFieldError(field.uid) }),
            npsSection));
    });
    return (React.createElement("div", null,
        React.createElement(KvSection, { enqueteSetting: enqueteSetting }),
        enqueteSetting.header && (React.createElement("h1", { className: "enquete-title" }, enqueteSetting.header)),
        React.createElement("div", { className: "enquete-description" }, lineBreakToParagraphAndHtml(enqueteSetting.description)),
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement("div", null,
                React.createElement(NameSection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, error: errorFields.name }),
                enqueteSetting.npsPositionAfterFieldUid === undefined && (React.createElement(NpsSection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, error: errorFields.nps })),
                fieldSections,
                React.createElement(CommentSection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, error: errorFields.comment }),
                React.createElement(PrivacyPolicySection, { enqueteSetting: enqueteSetting, enqueteForm: enqueteForm, setEnqueteForm: setEnqueteForm, error: errorFields.comment, isAgreePrivacyPolicy: isAgreePrivacyPolicy, setIsAgreePrivacyPolicy: setIsAgreePrivacyPolicy })),
            React.createElement("button", { type: "submit", disabled: submitting || !isAgreePrivacyPolicy, className: "enquete-next-button" }, msg.next))));
}
function NameSection(props) {
    var enqueteSetting = props.enqueteSetting, enqueteForm = props.enqueteForm, setEnqueteForm = props.setEnqueteForm, error = props.error;
    var name = enqueteForm.name;
    var msg = useContext(MessageContext).msg;
    var handleChangeName = function (e) {
        var name = e.target.value;
        setEnqueteForm(__assign(__assign({}, enqueteForm), { name: name }));
    };
    if (!enqueteSetting.visibleInputName) {
        return null;
    }
    return (React.createElement("div", { id: 'field-name', className: "input-text" },
        React.createElement("h2", { className: "input-text__title" },
            (enqueteSetting === null || enqueteSetting === void 0 ? void 0 : enqueteSetting.nameText) == null ? msg.name : enqueteSetting.nameText,
            React.createElement("label", { className: "required" }, msg.required)),
        error ? (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.pleaseEnter))) : null,
        React.createElement("div", null,
            React.createElement("input", { name: "name", type: "text", className: "input-text__input", value: name, onChange: handleChangeName, placeholder: msg.namePlaceholder }))));
}
function NpsSection(props) {
    var enqueteForm = props.enqueteForm, setEnqueteForm = props.setEnqueteForm, enqueteSetting = props.enqueteSetting, error = props.error;
    var npsOptionsText = enqueteSetting.npsOptionsText;
    var nps = enqueteForm.nps;
    var msg = useContext(MessageContext).msg;
    var handleChangeNps = function (e) {
        var nps = parseInt(e.target.value);
        setEnqueteForm(__assign(__assign({}, enqueteForm), { nps: nps }));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        scrollToNextQuestion(document.getElementById('field-nps'));
    };
    if (enqueteSetting.npsHidden) {
        return null;
    }
    return (React.createElement("div", { id: 'field-nps', className: "section" },
        React.createElement("h2", { className: "section-text" },
            enqueteSetting.npsText || msg.npsText,
            React.createElement("label", { className: "required" }, msg.required)),
        error ? (React.createElement("div", { className: "error-text" },
            React.createElement("p", null, msg.pleaseSelect))) : null,
        React.createElement("div", { className: "section-answer" },
            React.createElement("label", { className: 'choiceLabel' + (nps === 4 ? ' checked' : '') },
                React.createElement("input", { name: "nps", type: "radio", value: 4, checked: nps === 4, onChange: handleChangeNps }),
                (npsOptionsText === null || npsOptionsText === void 0 ? void 0 : npsOptionsText[4]) || msg.npsOption4),
            React.createElement("label", { className: 'choiceLabel' + (nps === 3 ? ' checked' : '') },
                React.createElement("input", { name: "nps", type: "radio", value: 3, checked: nps === 3, onChange: handleChangeNps }),
                (npsOptionsText === null || npsOptionsText === void 0 ? void 0 : npsOptionsText[3]) || msg.npsOption3),
            React.createElement("label", { className: 'choiceLabel' + (nps === 2 ? ' checked' : '') },
                React.createElement("input", { name: "nps", type: "radio", value: 2, checked: nps === 2, onChange: handleChangeNps }),
                (npsOptionsText === null || npsOptionsText === void 0 ? void 0 : npsOptionsText[2]) || msg.npsOption2),
            React.createElement("label", { className: 'choiceLabel' + (nps === 1 ? ' checked' : '') },
                React.createElement("input", { name: "nps", type: "radio", value: 1, checked: nps === 1, onChange: handleChangeNps }),
                (npsOptionsText === null || npsOptionsText === void 0 ? void 0 : npsOptionsText[1]) || msg.npsOption1))));
}
function CommentSection(props) {
    var enqueteSetting = props.enqueteSetting, enqueteForm = props.enqueteForm, setEnqueteForm = props.setEnqueteForm, error = props.error;
    var nps = enqueteForm.nps, comment = enqueteForm.comment;
    var msg = useContext(MessageContext).msg;
    var handleChangeComment = function (e) {
        var comment = e.target.value;
        setEnqueteForm(__assign(__assign({}, enqueteForm), { comment: comment }));
    };
    var text = (function () {
        var _a, _b, _c;
        if (enqueteSetting.npsHidden) {
            return (replacePlaceholder(enqueteForm, (_a = enqueteSetting.commentText) === null || _a === void 0 ? void 0 : _a.hidden) ||
                msg.commentEnterFeedback);
        }
        else if (nps === 1 || nps === 2) {
            return (replacePlaceholder(enqueteForm, (_b = enqueteSetting.commentText) === null || _b === void 0 ? void 0 : _b.negative) ||
                msg.commentEnterFeedback);
        }
        else if (nps === 3 || nps === 4) {
            return (replacePlaceholder(enqueteForm, (_c = enqueteSetting.commentText) === null || _c === void 0 ? void 0 : _c.positive) ||
                msg.commentEnterInputGoodPoint);
        }
        else {
            return msg.commentEnterFreeForm;
        }
    })();
    var placeholder = (function () {
        var _a, _b;
        if (enqueteSetting.npsHidden) {
            return (_a = enqueteSetting.commentPlaceholderNpsHidden) !== null && _a !== void 0 ? _a : '';
        }
        if (nps === 3 || nps === 4) {
            return (_b = enqueteSetting.commentPlaceholder) !== null && _b !== void 0 ? _b : '';
        }
        return '';
    })();
    // ポジティブの場合は最低入力文字数を表示
    var commentMinLength = nps === 3 || nps === 4 ? enqueteSetting.commentMinLength : undefined;
    useEffect(function () {
        if (enqueteForm.debug) {
            setEnqueteForm(__assign(__assign({}, enqueteForm), { nps: enqueteSetting.npsHidden ? undefined : enqueteForm.nps }));
        }
    }, [enqueteSetting.npsHidden]);
    return (React.createElement("div", { id: 'field-comment', className: "input-text" },
        React.createElement("h2", { className: "input-text__title" },
            lineBreakToBr(text),
            enqueteSetting.commentRequired !== false && (React.createElement("label", { className: "required" }, msg.required))),
        error ? (React.createElement("div", { className: "error-text" }, commentMinLength ? (React.createElement("p", null, msg.pleaseEnterTextLength(commentMinLength, comment.length))) : (React.createElement("p", null, msg.pleaseEnter)))) : null,
        React.createElement("div", null,
            React.createElement("textarea", { name: "comment", value: comment, onChange: handleChangeComment, placeholder: placeholder }),
            commentMinLength ? (React.createElement("div", { style: { fontSize: '12px', textAlign: 'right' } }, msg.pleaseEnterTextLength(commentMinLength, comment.length))) : null)));
}
function PrivacyPolicySection(props) {
    var enqueteSetting = props.enqueteSetting, isAgreePrivacyPolicy = props.isAgreePrivacyPolicy, setIsAgreePrivacyPolicy = props.setIsAgreePrivacyPolicy;
    var msg = useContext(MessageContext).msg;
    var isUrl;
    if (!enqueteSetting.privacyPolicy) {
        return null;
    }
    else {
        /* eslint-disable no-useless-escape */
        isUrl = /^https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+$/.test(enqueteSetting.privacyPolicy);
    }
    return (React.createElement("div", { className: "privacy-policy" }, isUrl ? (React.createElement("div", null,
        React.createElement("label", { className: 'privacyPolicyCheckbox' + (isAgreePrivacyPolicy ? ' checked' : '') },
            React.createElement("input", { id: "isAgreePrivacyPolicy", type: "checkbox", checked: isAgreePrivacyPolicy, onChange: function () { return setIsAgreePrivacyPolicy(!isAgreePrivacyPolicy); } })),
        msg.pleaseAgreeWithUrl(enqueteSetting.privacyPolicy))) : (React.createElement("div", null,
        React.createElement("div", { style: { marginBottom: '8px' } },
            React.createElement("label", { className: 'privacyPolicyCheckbox' +
                    (isAgreePrivacyPolicy ? ' checked' : '') },
                React.createElement("input", { id: "isAgreePrivacyPolicy", type: "checkbox", checked: isAgreePrivacyPolicy, onChange: function () { return setIsAgreePrivacyPolicy(!isAgreePrivacyPolicy); } })),
            React.createElement("label", { htmlFor: "isAgreePrivacyPolicy" },
                React.createElement("p", { className: "privacy-policy-text", style: {
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        margin: 0,
                    } }, msg.plaeseAgree))),
        React.createElement("textarea", { readOnly: true, name: "privacypolicy", value: enqueteSetting.privacyPolicy })))));
}
function FieldSection(props) {
    var _a, _b;
    var enqueteForm = props.enqueteForm, setEnqueteForm = props.setEnqueteForm, field = props.field, error = props.error;
    var msg = useContext(MessageContext).msg;
    var fieldValue = enqueteForm.fieldValues.find(function (fieldValue) {
        return fieldValue.fieldUid === field.uid;
    });
    useEffect(function () {
        if ((field.defaultValue && !fieldValue) ||
            (field.defaultValue && enqueteForm.debug)) {
            changeSingleValue(field.defaultValue);
        }
    }, [field.defaultValue]);
    var isChecked = function (option) {
        if (field.type === 'checkbox') {
            return ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.values) || []).includes(option.label);
        }
        else if (field.type === 'radio') {
            return option.label === (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value);
        }
        else {
            return false;
        }
    };
    var changeSingleValue = function (value) {
        if (fieldValue) {
            fieldValue.value = value;
            fieldValue.other = false;
            fieldValue.otherValue = undefined;
        }
        else {
            var newFieldValue = fieldValue ||
                {
                    fieldUid: field.uid,
                    value: value,
                };
            enqueteForm.fieldValues.push(newFieldValue);
        }
        setEnqueteForm(__assign({}, enqueteForm));
    };
    var changeSingleValueReset = function () {
        setEnqueteForm(__assign(__assign({}, enqueteForm), { fieldValues: enqueteForm.fieldValues.filter(function (e) { return e.fieldUid !== field.uid; }) }));
    };
    var changeMultiValue = function (value) {
        if (fieldValue && fieldValue.values) {
            var index = fieldValue.values.indexOf(value);
            if (index === -1) {
                fieldValue.values.push(value);
            }
            else {
                fieldValue.values.splice(index, 1);
            }
        }
        else {
            var newFieldValue = fieldValue ||
                {
                    fieldUid: field.uid,
                    values: [value],
                };
            enqueteForm.fieldValues.push(newFieldValue);
        }
        setEnqueteForm(__assign({}, enqueteForm));
    };
    var handleChangeText = function (e) {
        var value = e.target.value;
        changeSingleValue(value);
    };
    var handleChangeTextArea = function (e) {
        var value = e.target.value;
        changeSingleValue(value);
    };
    var handleChangeRadio = function (e) {
        var value = e.target.value;
        if (value === OTHER_VALUE) {
            changeSingleValueOther();
        }
        else {
            changeSingleValue(value);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scrollToNextQuestion(document.getElementById('field-' + field.uid));
        }
    };
    var handleChangeCheckBox = function (e) {
        var value = e.target.value;
        if (value === OTHER_VALUE) {
            changeMultiValueOther();
        }
        else {
            changeMultiValue(value);
        }
    };
    var handleChangeSelect = function (e) {
        var value = e.target.value;
        if (value === SELECT_NOT_SELECTED_VALUE) {
            changeSingleValueReset();
        }
        else if (value === OTHER_VALUE) {
            changeSingleValueOther();
        }
        else {
            changeSingleValue(value);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            scrollToNextQuestion(document.getElementById('field-' + field.uid));
        }
    };
    var changeMultiValueOther = function () {
        if (fieldValue) {
            fieldValue.other =
                fieldValue.other === undefined ? true : !fieldValue.other;
            if (!fieldValue.other) {
                fieldValue.otherValue = undefined;
            }
            setEnqueteForm(__assign({}, enqueteForm));
        }
        else {
            var newFieldValue = {
                fieldUid: field.uid,
                value: undefined,
                values: [],
                other: true,
                otherValue: '',
            };
            enqueteForm.fieldValues.push(newFieldValue);
            setEnqueteForm(__assign({}, enqueteForm));
        }
    };
    var changeSingleValueOther = function () {
        if (fieldValue) {
            fieldValue.other =
                fieldValue.other === undefined ? true : !fieldValue.other;
            if (fieldValue.other) {
                fieldValue.value = undefined;
            }
            else {
                fieldValue.otherValue = undefined;
            }
            setEnqueteForm(__assign({}, enqueteForm));
        }
        else {
            var newFieldValue = {
                fieldUid: field.uid,
                value: undefined,
                values: undefined,
                other: true,
                otherValue: '',
            };
            enqueteForm.fieldValues.push(newFieldValue);
            setEnqueteForm(__assign({}, enqueteForm));
        }
    };
    var handleChangeOtherValue = function (e) {
        if (!fieldValue) {
            return;
        }
        var otherValue = e.target.value;
        fieldValue.otherValue = otherValue;
        setEnqueteForm(__assign({}, enqueteForm));
    };
    var required = field.required === undefined ? true : field.required;
    var inputElement = undefined;
    if (field.type === 'text') {
        inputElement = (React.createElement("input", { name: field.uid, type: "text", value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _a !== void 0 ? _a : '', placeholder: field.placeholder || '', onChange: handleChangeText }));
    }
    else if (field.type === 'textarea') {
        inputElement = (React.createElement("textarea", { name: field.uid, onChange: handleChangeTextArea, placeholder: field.placeholder || '', value: (_b = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _b !== void 0 ? _b : '' }));
    }
    else if (field.type === 'radio') {
        inputElement = __spreadArray(__spreadArray([], field.options.map(function (option) {
            return (React.createElement("label", { key: option.label, className: 'choiceLabel' + (isChecked(option) ? ' checked' : '') },
                React.createElement("input", { name: field.uid, type: field.type, value: option.label, checked: isChecked(option), onChange: handleChangeRadio }),
                option.label));
        })), [
            field.showOtherOption ? (React.createElement("label", { className: 'choiceLabel' + ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other) ? ' checked' : '') },
                React.createElement("input", { name: field.uid, type: field.type, value: OTHER_VALUE, checked: !!(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other), onChange: handleChangeRadio }),
                msg.other)) : null,
            React.createElement(OtherElement, { key: field.uid + "-other", field: field, fieldValue: fieldValue, onChangeOtherValue: handleChangeOtherValue }),
        ]);
    }
    else if (field.type === 'checkbox') {
        inputElement = __spreadArray(__spreadArray([], field.options.map(function (option) {
            return (React.createElement("label", { key: option.label, className: 'choiceLabel' + (isChecked(option) ? ' checked' : '') },
                React.createElement("input", { name: field.uid, type: field.type, value: option.label, checked: isChecked(option), onChange: handleChangeCheckBox }),
                option.label));
        })), [
            field.showOtherOption ? (React.createElement("label", { className: 'choiceLabel' + ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other) ? ' checked' : '') },
                React.createElement("input", { name: field.uid, type: field.type, value: OTHER_VALUE, checked: !!(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other), onChange: handleChangeCheckBox }),
                msg.other)) : null,
            React.createElement(OtherElement, { key: field.uid + "-other", field: field, fieldValue: fieldValue, onChangeOtherValue: handleChangeOtherValue }),
        ]);
    }
    else if (field.type === 'select') {
        var calcSelectedValue = function () {
            if (fieldValue) {
                if (fieldValue.other) {
                    return OTHER_VALUE;
                }
                else {
                    return fieldValue.value;
                }
            }
            else {
                return SELECT_NOT_SELECTED_VALUE;
            }
        };
        inputElement = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "select-box-container" },
                !fieldValue && (React.createElement("div", { className: "select-box-placeholder" }, msg.pleaseSelect)),
                React.createElement("select", { className: "select-box", value: calcSelectedValue(), onChange: handleChangeSelect },
                    React.createElement("option", { value: SELECT_NOT_SELECTED_VALUE }),
                    field.options.map(function (option, i) { return (React.createElement("option", { key: field.uid + "-option-" + i, value: option.label }, option.label)); }),
                    field.showOtherOption && (React.createElement("option", { value: OTHER_VALUE }, msg.other)))),
            React.createElement(OtherElement, { key: field.uid + "-other", field: field, fieldValue: fieldValue, onChangeOtherValue: handleChangeOtherValue })));
    }
    if (field.type === 'text' || field.type === 'textarea') {
        return (React.createElement("div", { id: 'field-' + field.uid, className: "input-text" },
            React.createElement("h2", { className: "input-text__title" },
                field.text,
                ' ',
                required ? React.createElement("label", { className: "required" }, msg.required) : null),
            error && (React.createElement(TextFieldErrorMessage, { required: required, field: field, fieldValue: fieldValue })),
            React.createElement("div", null, inputElement)));
    }
    else {
        return (React.createElement("div", { id: 'field-' + field.uid, className: "section" },
            React.createElement("h2", { className: "section-text" },
                field.text,
                ' ',
                required ? React.createElement("label", { className: "required" }, msg.required) : null,
                ' ',
                field.type === 'checkbox' ? (React.createElement("label", { className: "multiple-select" }, msg.multipleSelectAllowed)) : null),
            error ? (React.createElement("div", { className: "error-text" },
                required && field.type === 'checkbox' ? (React.createElement("p", null, msg.pleaseSelectOneOrMore)) : null,
                required && (field.type === 'radio' || field.type === 'select') ? (React.createElement("p", null, msg.pleaseSelect)) : null)) : null,
            React.createElement("div", { className: "section-answer" }, inputElement)));
    }
}
function KvSection(props) {
    var enqueteSetting = props.enqueteSetting;
    if (enqueteSetting.iconImage && enqueteSetting.bgImage) {
        return (React.createElement("div", { className: "kv-bg", style: {
                backgroundImage: "url(" + enqueteSetting.bgImage + ")",
            } },
            React.createElement("div", { className: "kv-icon" },
                React.createElement("img", { alt: "", src: enqueteSetting.iconImage }))));
    }
    else if (enqueteSetting.bgImage) {
        return (React.createElement("div", { className: "kv-bg", style: {
                backgroundImage: "url(" + enqueteSetting.bgImage + ")",
                marginBottom: '24px',
            } }));
    }
    else if (enqueteSetting.iconImage) {
        return (React.createElement("div", { className: "kv-icon only" },
            React.createElement("img", { alt: "", src: enqueteSetting.iconImage })));
    }
    return null;
}
/**
 * その他の選択肢と自由入力欄
 * @param props
 * @returns
 */
var OtherElement = function (props) {
    var field = props.field, fieldValue = props.fieldValue, onChangeOtherValue = props.onChangeOtherValue;
    var msg = useContext(MessageContext).msg;
    var inputEl = useRef(null);
    var isCheckedOther = function () {
        if (field.type === 'checkbox' ||
            field.type === 'radio' ||
            field.type === 'select') {
            return (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.other) === true;
        }
        else {
            return false;
        }
    };
    useEffect(function () {
        if (isCheckedOther() && inputEl.current) {
            inputEl.current.focus();
        }
        return function () {
            if (inputEl.current) {
                inputEl.current.blur();
            }
        };
    }, [isCheckedOther()]);
    if (!field.showOtherOption) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("input", { hidden: !isCheckedOther(), ref: inputEl, type: "text", value: (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.otherValue) || '', placeholder: msg.otherPlaceholder, onChange: onChangeOtherValue }));
};
